.backdrop {
    /*background: rgba(158, 164, 236, 0.37);*/
    background: rgb(195 195 195 / 69%);/*rgba(133, 141, 243, 0.69);*//*rgba(121, 217, 255, 0.69)*/
    position: fixed;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
  }
  
  .lock-login { position: absolute;top: 150px;width: 100%;text-align: center;padding: 0px 10px 10px 15px; }
  .lock-login-container { width: 150px;/*height: 50px;*/background: white;border-radius: 2px;margin: auto;padding: 15px 10px; }
  .lock-login-link { color: black;text-decoration: none; }
  
  .lock-error-message { position: absolute;top: 220px;width: 100%;text-align: center;padding: 0px 10px 10px 15px; }
  .lock-error-message-container { width: 300px;/*height: 50px;*/background: white;border-radius: 2px;margin: auto;padding: 15px 10px; }
  .lock-error-message-text { color: red; }
  
  /*.principal-container {
    
    background-color:#5bc0de;
    height:800px;
    width:100% !important;
    
  }*/
  
  .page-lock .lock-centered {
    position: absolute;
    top: 42.5%;
    left: 0;
    right: 0;
    margin-top: -65px;
  }
  @media screen and (min-width: 768px) {
    .page-lock .lock-centered {
      margin-top: -75px;
    }
  }

  .page-lock .lock-container {
    position: relative;
    max-width: 450px;
    margin: 0 auto;
  }
  
  .page-lock .lock-box {
    position: absolute;
    left: 0;
    right: 0;
  }
  .page-lock .lock-box .lock-user {
    background: black;
    width: 50%;
    float: left;
    height: 50px;
    line-height: 50px;
    margin-top: 50px;
    padding: 0 20px;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px;
    color: #ffbf00;
    font-weight: bold;
  }

  .page-lock .lock-box .lock-img img, .page-lock .lock-box .lock-img section {
    position: absolute;
    border-radius: 50%;
    left: 40%;
    width: 80px;
    height: 80px;
    border: 6px solid white;
    background: white;
  }
  @media screen and (min-width: 768px) {
    .page-lock .lock-box .lock-img img, .page-lock .lock-box .lock-img section {
      left: 33%;
      width: 130px;
      height: 130px;
      border: 4px solid white;
    }
  }
  
  .page-lock .lock-box .lock-img section { background: #3372e8; display: flex; vertical-align: middle; text-align: center; }
  .page-lock .lock-box .lock-img section span { font-weight: bold; margin: auto; font-size: 4rem; bottom: 3px; position: relative; color: #052e7b; text-transform: uppercase; }
  
  .page-lock .lock-box .lock-pwd {
    background: white;
    width: 50%;
    float: right;
    height: 50px;
    line-height: 50px;
    padding: 0 0 0 50px;
    margin-top: 50px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px;
    color: #ffbf00;
  }
  @media screen and (min-width: 768px) {
    .page-lock .lock-box .lock-pwd {
      padding: 0 0 0 80px;
    }
  }
  .page-lock .lock-box .lock-pwd input {
    width: 80%;
    height: 50px;
    color: #555555;
    border: 0;
  }
  .page-lock .lock-box .lock-pwd .btn-submit {
    position: absolute;
    top: 50%;
    right: 20px;
  }
  /* ============ ESTILOS HIEBERNACION ================ */
  .clsHibernacionLogin{
    position: relative;
    top: 135px;
    padding: 8px 10px 8px 14px;
    background: #141516;
    border-radius: 1rem;
    display: flex;
    place-content: space-evenly;
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .clsHibernacionLogin div a:hover{
    color: #ffffff;
  }